import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingBarService {
  subject = new Subject<boolean>();

  constructor() {
  }

  getLoadingObj(): Observable<any> {
    return this.subject.asObservable();
  }

  show() {
    this.subject.next(true);
  }

  hide() {
    this.subject.next(false);
  }

}
